// JavaScript Document

jQuery(document).ready(function($){


    $( ".show1" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row1").slideUp();
            $( ".row1" ).slideToggle( "slow", function() {
    	});
     });
     
    $( ".show2" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row2").slideUp();
            $( ".row2" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show3" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row3").slideUp();
            $( ".row3" ).slideToggle( "slow", function() {
    	});
     });

   $( ".show41" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row41").slideUp();
            $( ".row41" ).slideToggle( "slow", function() {
    	});
     });
    
    $( ".show5" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row5").slideUp();
            $( ".row5" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show6" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row6").slideUp();
            $( ".row6" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show7" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row7").slideUp();
            $( ".row7" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show8" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row8").slideUp();
            $( ".row8" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show9" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row9").slideUp();
            $( ".row9" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show10" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row10").slideUp();
            $( ".row10" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show11" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row11").slideUp();
            $( ".row11" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show12" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row12").slideUp();
            $( ".row12" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show13" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row13").slideUp();
            $( ".row13" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show14" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row14").slideUp();
            $( ".row14" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show15" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row15").slideUp();
            $( ".row15" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show16" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row16").slideUp();
            $( ".row16" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show17" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row17").slideUp();
            $( ".row17" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show4" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row4").slideUp();
            $( ".row4" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show18" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row18").slideUp();
            $( ".row18" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show19" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row19").slideUp();
            $( ".row19" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show20" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row20").slideUp();
            $( ".row20" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show21" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row21").slideUp();
            $( ".row21" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show22" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row22").slideUp();
            $( ".row22" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show23" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row23").slideUp();
            $( ".row23" ).slideToggle( "slow", function() {
    	});
    });
    
    $( ".show24" ).click(function(e) {
        e.preventDefault();
            $(".row").not(".row24").slideUp();
            $( ".row24" ).slideToggle( "slow", function() {
    	});
    });
    

});